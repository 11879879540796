<template>
  <div>
    <addDetail></addDetail>
  </div>
</template>

<script>
// import api from "@/api/guildManageapi";
import addDetail from "./components/addDetail"
export default {
  name: "noticeadd",
  components: { addDetail },
  data () {
    return {
      form: {},
      type: 0,
      switchValue: 1,
    };
  },
  created () {
    this.$store.commit("layouts/setPathList", [{ name: '公告管理', path: '/backstage/noticepage' }, { name: '新增' }]);
    this.$store.commit("layouts/changeBreadcrumb", true)
  },
  destroyed () {
    this.$store.commit("layouts/changeBreadcrumb", false);
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
// @import "./headCss.less";
</style>